import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/": [9],
		"/error": [37],
		"/explore": [~38],
		"/explore/[species]/[valueChain]/[geography]": [~39,[],[8]],
		"/home": [~40],
		"/profile": [41],
		"/read": [42],
		"/read/templates/[templateId]/latest": [~44],
		"/read/[articleId]": [~43],
		"/tables": [45],
		"/unauthorized": [46],
		"/[context]": [10,[2]],
		"/[context]/analysis": [~12,[2,3]],
		"/[context]/analysis/pelagics/analysisupdate/[...articleId]": [~14,[2,3]],
		"/[context]/analysis/preview/[articleId]": [15,[2,3]],
		"/[context]/analysis/salmonids/analysisupdate/[...articleId]": [~16,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate": [17,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/marketupdates/[...articleId]": [~18,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/productionupdates/[...articleId]": [~19,[2,3,4]],
		"/[context]/analysis/salmonids/weeklyupdate/[...articleId]": [~20,[2,3,4]],
		"/[context]/analysis/shrimp/analysisupdate/[...articleId]": [~21,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate": [22,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/marketupdates/[...articleId]": [~23,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/productionupdates/[...articleId]": [~24,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/tradeupdates/[...articleId]": [~25,[2,3,5]],
		"/[context]/analysis/shrimp/weeklyupdate/[...articleId]": [~26,[2,3,5]],
		"/[context]/analysis/templates/[templateId]/latest": [~27,[2,3]],
		"/[context]/analysis/[group]/[category]/[...articleId]": [~13,[2,3]],
		"/[context]/feedback": [~28,[2]],
		"/[context]/industry": [29,[2]],
		"/[context]/industry/companies": [30,[2,6]],
		"/[context]/industry/companies/[slug]": [31,[2,6]],
		"/[context]/resources/abbreviations": [~32,[2,7]],
		"/[context]/resources/definitions": [~33,[2,7]],
		"/[context]/resources/product-categories": [~34,[2,7]],
		"/[context]/resources/weight-conversions": [~35,[2,7]],
		"/[context]/upgrade": [~36,[2]],
		"/[context]/[menu]/[species]/[valueChain]/[geography]": [11,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';